import { useQuery } from '@tanstack/react-query';
import { Database } from '~/database.types';
import { request } from '../util';
import { AdditionalQueryOptions, Assistant, AssistantRole } from '../types';
import { AssistantDetails } from '../types';
import { GetAssistantAssistantsIdGetResponse } from '@portal/chat-sdk';

type API<K extends keyof Database['public']['Tables'], A extends keyof Database['public']['Tables'][K]> = {
  data: Database['public']['Tables'][K][A];
};

export function useAssistantDetailsQuery(
  id: string | undefined | null,
  options: AdditionalQueryOptions<AssistantDetails, ['assistant', string, 'details']> = {},
) {
  return useQuery({
    ...options,
    enabled: typeof id === 'string',
    queryKey: ['assistant', id ?? '', 'details'],
    async queryFn() {
      const { data } = await request<{ data: Database['public']['Tables']['assistant_details']['Row'] }>({
        pathname: `/api/v2/assistants/${id}/details`,
      });
      return data;
    },
  });
}

export function useAssistantQuery(id: string, options: AdditionalQueryOptions<Assistant, ['assistants', string]> = {}) {
  return useQuery({
    ...options,
    queryKey: ['assistants', id],
    async queryFn() {
      const { assistant } = await request<{ assistant: Assistant }>({
        pathname: `/api/v2/assistants/${id}`,
      });
      return assistant;
    },
  });
}

export function useAssistantsQuery() {
  return useQuery({
    queryKey: ['assistants'],
    async queryFn() {
      return request<{ data: Database['public']['Tables']['assistants']['Row'][] }>({
        pathname: '/api/v2/assistants',
      });
    },
  });
}

export function useAssistantsToolQuery(
  { id }: { id: string },
  {
    enabled = true,
    ...options
  }: AdditionalQueryOptions<GetAssistantAssistantsIdGetResponse, readonly ['assistants', 'tools', string]> = {},
) {
  return useQuery({
    ...options,
    enabled: typeof id === 'string' && enabled,
    queryKey: ['assistants', 'tools', id],
    async queryFn() {
      return request<GetAssistantAssistantsIdGetResponse>({
        pathname: `/api/v2/assistants/${id}/tools`,
      });
    },
  });
}

type WordCount = {
  assistant_id: string;
  created_at: Date;
  data_object_id: string;
  id: number;
  word_count: number;
};

// Define the type for the response from the API
type WordCountResponse = WordCount[];
export function useAssistantWordCountQuery(
  id: string,
  options: AdditionalQueryOptions<WordCountResponse, ['assistant', string, 'word_count']> = {},
) {
  return useQuery({
    ...options,
    queryKey: ['assistant', id, 'word_count'],
    async queryFn() {
      const { data } = await request<{ data: WordCountResponse }>({
        pathname: `/api/v2/assistants/${id}/word_count`,
      });
      return data;
    },
  });
}

type MyAssistant = {
  bucket_id: string;
  created_at: string;
  created_by: string;
  description: string | null;
  id: string;
  image_url: string | null;
  is_personal: boolean;
  name: string;
  old_id: number;
  organization_id: number;
  permission: Database["public"]["Enums"]["app_permissions"];
};

type MyAssistantsResponse = {
  data: MyAssistant[];
  personalAssistant: MyAssistant[];
};

export function useMyAssistantsQuery() {
  return useQuery<MyAssistantsResponse>({
    queryKey: ['my-assistants'],
    queryFn: async () => {
      const response = await request<MyAssistantsResponse>({
        pathname: '/api/v2/assistants/my-assistants',
      });
      return response;
    },
  });
}


type SharedAssistantsResponse = {
  data: MyAssistant[];
};

export function useSharedAssistantsQuery() {
  return useQuery<SharedAssistantsResponse>({
    queryKey: ['shared-assistants'],
    queryFn: async () => {
      const response = await request<SharedAssistantsResponse>({
        pathname: '/api/v2/assistants/shared-assistants',
      });
      return response;
    },
  });
}

export function useAssistantUsersQuery(
  id:  string | null | undefined,
  options: AdditionalQueryOptions<AssistantRole, ['assistant_users', string | null | undefined]> = {},
) {
  return useQuery({
    ...options,
    queryKey: ['assistant_users', id],
    async queryFn() {
      const { data } = await request<{ data: AssistantRole }>({
        pathname: `/api/v2/assistants/${id}/users`,
      });
      return data;
    },
  });
}